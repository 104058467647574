import { computed, defineComponent } from 'vue';
import { darkTheme, NConfigProvider, NDialogProvider, NLayout, NLayoutContent, NMessageProvider, ptBR, datePtBR } from 'naive-ui';
import { RouterView } from 'vue-router';

import { AppHeader, AppLogin } from './components/App';
import { UserStore } from './stores';
import { AuthService } from './services';
import { darkThemeOverrides } from './themes/dark';
import { lightThemeOverrides } from './themes/light';

import './services/amplitudeService';

import { useFeatBitStore } from './services/featbitService';
import { vMaska } from 'maska/vue';

export const App = defineComponent({
	directives: { maska: vMaska },
	setup() {
		const userStore = UserStore();
		const user = computed(() => {
			return userStore.user;
		});

		const theme = computed(() => {
			return userStore.appTheme == 'light' ? null : darkTheme;
		});

		const themeOverride = computed(() => {
			return userStore.appTheme == 'light' ? lightThemeOverrides : darkThemeOverrides;
		});

		const showLogin = computed(() => {
			return userStore.showLoginPrompt;
		});

		const isGlobalSearchEnabled = computed(() => {
			const featbit = useFeatBitStore();
			return featbit.flags['global-search'] as boolean;
		});

		return {
			locale: ptBR,
			dateLocale: datePtBR,
			user,
			theme,
			showLogin,
			themeOverride,
			isGlobalSearchEnabled,
		};
	},

	mounted() {
		const auth = new AuthService();
		auth.authorizeFlow();
	},

	render() {
		return (
			<NConfigProvider theme={this.theme} locale={this.locale} dateLocale={this.dateLocale} theme-overrides={this.themeOverride}>
				{/* <NThemeEditor show-panel={process.env.NODE_ENV !== 'development'}> */}
				<NDialogProvider>
					<NMessageProvider>
						{!this.showLogin && (
							<NLayout>
								<AppHeader />
								<NLayoutContent class="app-base-layout" content-style="padding: 8px; overflow-y: auto; height: calc(100vh - 71px)">
									<RouterView></RouterView>
								</NLayoutContent>
							</NLayout>
						)}

						{this.showLogin && (
							<NLayout class="app-base-layout" position="absolute">
								<AppLogin />
							</NLayout>
						)}
					</NMessageProvider>
				</NDialogProvider>
				{/* </NThemeEditor> */}
			</NConfigProvider>
		);
	},
});

export default App;
