import { NButton, NIcon, NImage, NLayoutHeader, NMenu, NSpace, type MenuOption, NText, NInput, NConfigProvider, darkTheme } from 'naive-ui';
import { computed, defineComponent, ref, withKeys } from 'vue';

import logo from '@/assets/logo.svg';
import { MenuItem } from '@/models';
import { Icons, Routes } from '@/utils';
import { AppUserMenu } from '.';
import { RenderIcon } from '..';
import { headerMenuTheme } from '@/themes/global';
import { UserStore } from '@/stores';
import { AppUserNotification } from './AppUserNotification';
import { CaptureEvent, Events } from '@/services/amplitudeService';
import router from '@/router/router';
import { darkThemeOverrides } from '@/themes/dark';

export const AppHeader = defineComponent({
	setup() {
		const userStore = UserStore();

		const currentTheme = computed(() => userStore.theme);

		const menuOptions = computed(() => {
			var subMenuManagment: MenuOption[] = [];

			if (userStore.isUserAdmin || userStore.isBusinessConsultant || userStore.isBusinessManager) subMenuManagment.push(MenuItem.create('Parceiros', Routes.Brokers));
			if (userStore.isUserAdmin || userStore.isBrokerAdmin || userStore.isBusinessConsultant || userStore.isBusinessManager) subMenuManagment.push(MenuItem.create('Usuários', Routes.ManagementUsers));
			if (userStore.isUserAdmin) subMenuManagment.push(MenuItem.create('Política de Crédito', Routes.ManagementCreditPolicyView));
			if (userStore.isUserAdmin || userStore.isUser) subMenuManagment.push(MenuItem.create('Financeiro', Routes.ManagementFinancialBrokerBills));
			if (userStore.isUserAdmin || userStore.isUser) subMenuManagment.push(MenuItem.create('Jurídico', Routes.Legal));

			return [
				MenuItem.create('Inicio', Routes.Home, Icons.Home),
				MenuItem.create('Simulador', Routes.Simulator, Icons.Simulator),
				MenuItem.create('Produtos', Routes.Cases, Icons.Product, [
					MenuItem.create('Crédito Imobiliário', Routes.RealEstateFinancingCases, Icons.MortgageProduct),
					MenuItem.create('Garantia Locatícia', '', Icons.Inbox, [MenuItem.create('Propostas', Routes.Cases, Icons.Proposal), MenuItem.create('Contratos', Routes.Contracts, Icons.Contract)]),
				]),
				userStore.isBrokerAdmin ? MenuItem.create('Financeiro', Routes.BrokerFinancial, Icons.Financial) : undefined,
				userStore.isUserAdmin || userStore.isBrokerAdmin || userStore.isBusinessConsultant || userStore.isBusinessManager ? MenuItem.create('Gerenciamento', Routes.Management, Icons.GroupOutlined, subMenuManagment) : undefined,
			].filter((c) => c != undefined) as MenuOption[];
		});

		const searchQuery = ref('');

		return {
			activeKey: ref<string | null>(null),
			logo,
			menuOptions,
			searchQuery,
			currentTheme,
			handleSearch() {
				if (!!searchQuery.value) router.push({ name: Routes.Search, query: { query: searchQuery.value } });
			},
			toggleTheme() {
				userStore.toggleTheme();
				CaptureEvent(Events.ToggleTheme, { theme: userStore.theme });
			},
		};
	},
	render() {
		return (
			<NConfigProvider theme-overrides={darkTheme}>
				<NLayoutHeader>
					<NSpace align="center" justify="space-between" class="px2 py1">
						<div>
							<NImage src={this.logo} preview-disabled height="48" class="m0" />

							{process.env.NODE_ENV === 'development' && <NText style="color: #ff0000">DEV ENV - {window.appVersion}</NText>}
						</div>
						<NSpace>
							<NMenu theme-overrides={headerMenuTheme} class="app-header-menu" v-model:value={this.activeKey} expandIcon={RenderIcon(Icons.ChevronDown)} collapsedIconSize={22} mode="horizontal" options={this.menuOptions} />

							<NInput style="margin-top: 4px" minlength={3} type="text" placeholder="Pesquisar" v-model:value={this.searchQuery} onKeypress={withKeys(() => this.handleSearch(), ['enter'])}>
								{{
									suffix: () => <NIcon size="24" component={Icons.Search} />,
								}}
							</NInput>
						</NSpace>

						<NSpace align="center">
							<AppUserNotification />
							<NButton onClick={this.toggleTheme} secondary type="default" class="p1">
								<NIcon size="24" component={this.currentTheme == 'light' ? Icons.LightMode : Icons.DarkMode} />
							</NButton>
							<AppUserMenu />
						</NSpace>
					</NSpace>
				</NLayoutHeader>
			</NConfigProvider>
		);
	},
});

export default AppHeader;
