import * as amplitude from '@amplitude/analytics-browser';

if (import.meta.env.PROD) amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY);

export const Events = {
  NotificationsClicked: '[WebApp] Notifications Clicked',
  WorkflowActionCompleted: '[WebApp] Workflow Action Completed',
  WorkflowActionError: '[WebApp] Workflow Action Error',
  MessageSent: '[WebApp] Message Sent',
  SimulationCreated: '[WebApp] Simulation Created',
  ToggleTheme: '[WebApp] Toggle Theme',
  SimulatorPrinted: '[WebApp] Simulator Printed',
};

export function CaptureEvent(eventName: string, eventProperties?: any) {
  amplitude.track(eventName, eventProperties);
}

export default amplitude;
