import { defineComponent } from "vue";

import "./LoadingZoo.scss"
import { NSpace, NText } from "naive-ui";

export const LoadingZoo = defineComponent({
    name: "LoadingZoo",
    props: {
        hideText: {
            type: Boolean,
            default: false
        },

        size: {
            type: Number,
            default: 48
        }
    },
    render() {
        return (
            <NSpace vertical align="center" class="active">
                <div>
                    <svg width={this.size} height={this.size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M55.808 1.07947H1.01099V56.1505H55.808V1.07947Z" stroke="#A4843D" stroke-width="2.022" stroke-linecap="round" class="zoo-loading-1"></path>
                        <path d="M37.853 18.5697C38.422 18.5697 38.8834 18.0949 38.8834 17.5092C38.8834 16.9235 38.422 16.4487 37.853 16.4487C37.2839 16.4487 36.8226 16.9235 36.8226 17.5092C36.8226 18.0949 37.2839 18.5697 37.853 18.5697Z" fill="#213945" class="zoo-loading-2"></path>
                        <path d="M31.2126 13.5027L32.6751 12.6496L39.6218 14.3559L41.2062 15.8183L43.1562 24.9588L41.0843 25.3244L36.9406 30.8087L35.9656 27.2744L31.5782 21.7901L31.2126 13.5027Z" fill="url(#paint0_linear_0_1)" class="zoo-loading-3"></path>
                        <path d="M16.9534 26.0557L34.625 28.1275L36.2094 31.0525L33.4063 36.4149L31.9438 38.6086L19.1472 38.8523L13.4191 30.565L16.9534 26.0557Z" fill="url(#paint1_linear_0_1)" class="zoo-loading-4"></path>
                        <path d="M10.7379 21.1807L17.0753 25.0807L34.7469 27.3963L30.4814 22.2776L30.1157 13.5027L19.3909 9.11531L10.4942 13.8684L10.7379 21.1807Z" fill="url(#paint2_linear_0_1)" class="zoo-loading-5"></path>
                        <path d="M46.4467 28.0056L46.203 29.7118L48.8842 28.1275L48.7623 25.2026L43.5218 22.6432L42.1812 16.3058L50.1029 21.3026L50.5904 24.5932L51.4435 28.4931L48.1529 31.4181L43.6436 31.1743L42.5468 27.8838L44.6186 25.9338L46.4467 28.0056Z" fill="url(#paint3_linear_0_1)" class="zoo-loading-6"></path>
                        <path d="M44.8624 39.8273L40.5968 33.8555L36.4531 32.2712L34.2594 36.293L37.3063 38.9742L37.0625 40.3148L38.8906 41.5335L44.8624 39.8273Z" fill="url(#paint4_linear_0_1)" class="zoo-loading-7"></path>
                        <path d="M26.2158 39.7054L24.7533 41.7773L18.9034 44.946L17.1972 42.6304L18.4159 39.7054H26.2158Z" fill="url(#paint5_linear_0_1)" class="zoo-loading-8"></path>
                        <path d="M17.9284 39.0961L14.7597 44.3366L7.81297 48.1147L6.10675 42.2648L10.2504 39.7054L12.0785 40.4367L13.4191 31.9056L17.9284 39.0961Z" fill="url(#paint6_linear_0_1)" class="zoo-loading-9"></path>
                        <path d="M11.8348 34.0993L12.0785 32.1493L8.66609 30.8087L7.20362 25.3244L9.03171 23.3745L6.47238 21.912L5.37552 25.2026L6.95987 30.9306L11.8348 34.0993Z" fill="url(#paint7_linear_0_1)" class="zoo-loading-10"></path>
                        <defs>
                            <linearGradient id="paint0_linear_0_1" x1="31.2126" y1="21.7292" x2="42.8844" y2="17.5165" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5B6873"></stop>
                                <stop offset="1" stop-color="#5B6873" stop-opacity="0"></stop>
                            </linearGradient>
                            <linearGradient id="paint1_linear_0_1" x1="34.3468" y1="32.0463" x2="6.58454" y2="38.4333" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#918C8E"></stop>
                                <stop offset="1" stop-color="#918C8E" stop-opacity="0"></stop>
                            </linearGradient>
                            <linearGradient id="paint2_linear_0_1" x1="24.763" y1="17.984" x2="2.13239" y2="25.8658" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#999BAA"></stop>
                                <stop offset="1" stop-color="#999BAA" stop-opacity="0"></stop>
                            </linearGradient>
                            <linearGradient id="paint3_linear_0_1" x1="47.8423" y1="35.2738" x2="50.5602" y2="9.31807" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#213945"></stop>
                                <stop offset="1" stop-color="#213945" stop-opacity="0"></stop>
                            </linearGradient>
                            <linearGradient id="paint4_linear_0_1" x1="42.4131" y1="31.6025" x2="34.5344" y2="45.1919" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5B6073"></stop>
                                <stop offset="1" stop-color="#5B6073" stop-opacity="0"></stop>
                            </linearGradient>
                            <linearGradient id="paint5_linear_0_1" x1="22.4971" y1="43.4129" x2="19.557" y2="35.2592" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#354248"></stop>
                                <stop offset="1" stop-color="#354248" stop-opacity="0"></stop>
                            </linearGradient>
                            <linearGradient id="paint6_linear_0_1" x1="12.4938" y1="42.5921" x2="23.636" y2="29.5463" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#797480"></stop>
                                <stop offset="1" stop-color="#797480" stop-opacity="0"></stop>
                            </linearGradient>
                            <linearGradient id="paint7_linear_0_1" x1="4.6281" y1="35.2738" x2="14.4125" y2="23.9946" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#646B70"></stop>
                                <stop offset="1" stop-color="#646B70" stop-opacity="0"></stop>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                {!this.hideText && (
                    <div>
                        <NText depth="3" strong>Carregando...</NText>
                    </div>
                )}
            </NSpace>
        )
    }
})