import { UserStore } from '@/stores';
import { Colors } from '@/utils';
import { lightThemeOverrides } from './light';
import { darkThemeOverrides } from './dark';

export const headerMenuTheme = {
	itemTextColorHorizontal: '#ffffff',
	itemTextColor: '#fff',
	textColor: '#fff',
	itemIconColor: '#ffffff',
	itemTextColorActiveHorizontal: Colors.primaryColor,
	itemIconColorActiveHorizontal: Colors.primaryColor,
	itemTextColorHoverHorizontal: Colors.primaryColor,
	itemTextColorActiveHoverHorizontal: Colors.primaryColor,
	itemIconColorHoverHorizontal: Colors.primaryColor,
	itemIconColorActiveHoverHorizontal: Colors.primaryColor,
	itemTextColorChildActiveHorizontal: Colors.primaryColor,
	itemIconColorChildActiveHorizontal: Colors.primaryColor,
};

export function getTheme() {
	var userStore = UserStore();
	return userStore.appTheme == 'light' ? lightThemeOverrides : darkThemeOverrides;
}
