import axios from 'axios';

// const UOL_API_MARKET = "https://api.cotacoes.uol.com/mixed/summary?&currencies=1,11,5&itens=1,23243,1168&fields=name,openbidvalue,askvalue,variationpercentbid,price,exchangeasset,open,pctChange,date,abbreviation";
// const CNN_API_NEWS = "https://www.cnnbrasil.com.br/economia/feed";

import { Http } from './http';
import type { IResponseBase } from '@/interfaces';
import type { IClient } from '@/interfaces/IRenter';

const http = Http.create();

export class PublicService {
    static async getMarket() {
        const response = await http.get<{
            data: Array<{
                name: string;
                value: number;
                variation: number;
            }>;
        }>('/services/market');

        return response.data;
    }

    static async getLastNews() {
        const response = await http.get<{
            data: Array<{
                _publishDate: string | number | Date;
                title: string;
                description: string;
                link: string;
                source: string;
                publishdate: string;
            }>;
        }>('/services/feed');

        return response.data;
    }

    static async getRenter(document: string) {
        const response = await http.get<IResponseBase<IRenter>>('/services/client', { params: { document } });

        return response.data;
    }
}
