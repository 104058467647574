import Colors from '@/utils/colors';
import { globalTheme } from './globalTheme';
export const lightThemeOverrides = {
	...globalTheme,
	common: {
		primaryColor: '#1D2945',
		primaryColorHover: Colors.primaryColor,
	},
	Layout: {
		headerColor: Colors.softBackground,
		footerColor: Colors.mainBackgroundColor,
		siderColor: '#fff',
	},
	DataTable: {
		borderColor: '#F5F5F5',
		thColor: '#f5f5f7',
		tdColor: '#fff',
		tdColorHover: '#f9f9ff',
	},
	Table: {
		borderColor: '#F5F5F5',
		thColor: '#f5f5f7',
		tdColor: '#fff',
		tdColorHover: '#F5F5F5',
	},
	Card: {
		color: '#FFF',
	},
	List: {
		// color: Colors.mainBackgroundColor,
		// borderColor: Colors.strokeColor,
	},
	Descriptions: {
		thFontWeight: 'bold',
		thColor: '',
		tdColor: '',
		borderColor: '#f5f5f7',
	},

	Input: {
		color: '#f5f5f7',
	},
};
