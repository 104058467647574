import { MAX_FINANCIAL_TERM_RESIDENTIAL } from '@/constants';
import { DateHelper } from './DateHelper';

export class Validations {
	static isDateGreaterThanYesterday(value: string) {
		const parsedDate = DateHelper.parseDate(value);

		const currentDate = new Date();
		currentDate.setDate(currentDate.getDate() - 1);

		return parsedDate >= currentDate;
	}
	static isArrayFullFilled(value: any[]) {
		return value && value.length > 0;
	}
	/**
	 * Check if email is valid
	 * @param email
	 * @returns
	 */
	public static validateEmail(email: string) {
		return String(email)
			.toLowerCase()
			.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
			? true
			: false;
	}

	/**
	 * Validate a CPF Document
	 * @param cpf
	 * @returns
	 */
	public static validateCpf(cpf: string) {
		if (!cpf || cpf.trim().length === 0) return false;

		// Remove caracteres inválidos do CPF
		cpf = cpf.replace(/[^\d]/g, '');

		// Verifica se o CPF possui 11 dígitos
		if (cpf.length !== 11) {
			return false;
		}

		// Verifica se todos os dígitos são iguais
		if (/^(\d)\1{10}$/.test(cpf)) {
			return false;
		}

		// Verifica se o primeiro dígito verificador está correto
		let sum = 0;
		for (let i = 0; i < 9; i++) {
			sum += parseInt(cpf.charAt(i)) * (10 - i);
		}
		let remainder = 11 - (sum % 11);
		let digit = remainder > 9 ? 0 : remainder;
		if (digit !== parseInt(cpf.charAt(9))) {
			return false;
		}

		// Verifica se o segundo dígito verificador está correto
		sum = 0;
		for (let i = 0; i < 10; i++) {
			sum += parseInt(cpf.charAt(i)) * (11 - i);
		}
		remainder = 11 - (sum % 11);
		digit = remainder > 9 ? 0 : remainder;
		if (digit !== parseInt(cpf.charAt(10))) {
			return false;
		}

		return true;
	}

	/**
	 * Validate a CNPJ
	 * @param value
	 * @returns
	 */
	public static validateCnpj(value: string) {
		if (!value) return false;

		const isString = typeof value === 'string';
		const validTypes = isString || Number.isInteger(value) || Array.isArray(value);

		if (!validTypes) return false;

		if (isString) {
			if (value.length > 18) return false;

			const digitsOnly = /^\d{14}$/.test(value);
			const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

			if (digitsOnly || validFormat) true;
			else return false;
		}

		const match = value.toString().match(/\d/g);
		const numbers = Array.isArray(match) ? match.map(Number) : [];

		if (numbers.length !== 14) return false;

		const items = [...new Set(numbers)];
		if (items.length === 1) return false;

		const calc = (x: number) => {
			const slice = numbers.slice(0, x);
			let factor = x - 7;
			let sum = 0;

			for (let i = x; i >= 1; i--) {
				const n = slice[x - i];
				sum += n * factor--;
				if (factor < 2) factor = 9;
			}

			const result = 11 - (sum % 11);

			return result > 9 ? 0 : result;
		};

		const digits = numbers.slice(12);

		const digit0 = calc(12);
		if (digit0 !== digits[0]) return false;

		const digit1 = calc(13);
		return digit1 === digits[1];
	}

	/**
	 *
	 * @param dateStr
	 * @returns
	 */
	public static isDateValid(dateStr: string): boolean {
		if (!dateStr || dateStr.length == 0) return false;
		try {
			var date = DateHelper.parseDate(dateStr);

			return date instanceof Date && !isNaN(date.getTime());
		} catch (error) {
			return false;
		}
	}

	public static calculateYearsDiff(dateStr: string): number {
		const currentDate = new Date();
		const parsedDate = DateHelper.parseDate(dateStr);
		const diffInMs = currentDate.getTime() - parsedDate.getTime();
		const yearsDiff = diffInMs / (1000 * 60 * 60 * 24 * 365);

		return yearsDiff;
	}

	public static isOver18YearsOld(dateStr: string): boolean {
		return Validations.calculateYearsDiff(dateStr) >= 18;
	}

	public static isOver65YearsOld(dateStr: string): boolean {
		return Validations.calculateYearsDiff(dateStr) >= 65;
	}

	public static isMinimalOpeningDate(dateStr: string): boolean {
		return Validations.calculateYearsDiff(dateStr) >= 2;
	}

	/**
	 * Validate if phone number is valid
	 */
	public static validatePhoneNumber(phoneNumber: string) {
		if (!phoneNumber || phoneNumber.trim().length === 0) return false;

		// Remove caracteres inválidos do CPF
		phoneNumber = phoneNumber.replace(/[^\d]/g, '');

		// Verifica se o telefone possui 11 dígitos
		if (phoneNumber.length > 9) {
			return false;
		}

		// Verifica se todos os dígitos são iguais
		if (/^(\d)\1{10}$/.test(phoneNumber)) {
			return false;
		}

		return true;
	}

	/**
	 * Should validate if the fullname is valid
	 * If contain more then 2 names
	 * @param fullname
	 */
	public static validateFullname(fullname: string) {
		if (!fullname || fullname.trim().length === 0) return false;

		//regex to validate names
		var regex = /^[a-zA-Z\s]+$/;
		if (!regex.test(fullname)) return false;

		const names = fullname.split(' ');

		return names.length > 1;
	}

	public static validateMaxFinancingTerm(birthDate: string, term: number) {
		const today = new Date();

		const age = DateHelper.diffBetweenDates(today, birthDate, 'years');

		const maxTermInYears = 80.5 - age;
		const maxTermInMonths = maxTermInYears * 12;
		const calculatedTerm = Math.min(maxTermInMonths, term);

		return calculatedTerm;
	}
}

export default Validations;
