import { AuthService } from '@/services';
import { UserStore } from '@/stores';
import { Icons, Routes } from '@/utils';
import { NButton, NDropdown, NIcon, NSpace, NText, useDialog, useMessage } from 'naive-ui';
import { computed, defineComponent, h } from 'vue';
import RenderIcon from '../RenderIcon';
import AppUserAvatar from './AppUserAvatar';
import { useRouter } from 'vue-router';

export const AppUserMenu = defineComponent({
	setup(props, attrs) {
		const dialog = useDialog();
		const userStore = UserStore();
		const router = useRouter();

		const userEmail = computed(() => userStore.user?.email);

		const renderCustomHeader = () => {
			return h(
				'div',
				{
					style: 'display: flex; align-items: center; padding: 8px 12px;',
				},
				[
					h(AppUserAvatar, { content: userEmail.value || 'A', showTooltip: false }, {}),
					h('div', { style: 'margin-left: 8px' }, [h('div', null, [h(NText, { depth: 2 }, { default: () => '' })]), h('div', { style: 'font-size: 12px;' }, [h(NText, { depth: 3 }, { default: () => userEmail.value })])]),
				]
			);
		};

		return {
			userStore,
			userEmail,
			options: [
				{
					key: 'header',
					type: 'render',
					render: renderCustomHeader,
				},
				{
					label: 'Configurações',
					key: 'settings',
					icon: RenderIcon(Icons.Settings),
					props: {
						onClick: () => router.push({ name: Routes.AccountView }),
					},
				},
				{
					label: 'Sair',
					key: 'logout',
					icon: RenderIcon(Icons.Logout),
					props: {
						onClick: () => {
							dialog.warning({
								title: 'Confirm',
								content: 'Tem certeza que deseja encerrar sua sessão?',
								positiveText: 'Sim',
								negativeText: 'Não',
								onPositiveClick: () => AuthService.logout(),
							});
						},
					},
				},
			],
		};
	},

	render() {
		return (
			<NDropdown options={this.options} trigger="click" placement="bottom-start" size="medium">
				<NButton round class="px1" size="large" secondary type="default">
					<NSpace justify="space-between" align="center">
						<AppUserAvatar circle size={32} class="mr3 mt1" showTooltip={false} content={this.userEmail || 'A'} />
						<NIcon size="18" component={Icons.Settings} />
					</NSpace>
				</NButton>
			</NDropdown>
		);
	},
});

/* <NText strong>{this.userEmail}</NText> */

export default AppUserMenu;
