export enum Routes {
	Home = 'APP.HOME',
	DashboardGeral = 'APP.DASHBOARD.GERAL',
	CaseRoot = 'APP.CASES.ROOT',
	CasesList = 'APP.CASES.PROPOSALS',
	Cases = 'APP.CASES.PROPOSALS',
	Contracts = 'APP.CASES.CONTRACTS',
	Brokers = 'APP.BROKERS',
	BrokersNew = 'APP.BROKERS.NEW',
	Simulator = 'APP.Simulador',
	Management = 'APP.MANAGEMENT.USERS',
	ManagementUsers = 'APP.MANAGEMENT.USERS',
	ManagementUserNew = 'APP.MANAGEMENT.USERS.NEW',
	ManagementUserView = 'APP.MANAGEMENT.USERS.VIEW',
	ManagementBrokerView = 'APP.MANAGEMENT.BROKER.VIEW',
	ManagementBrokerCreditPolicyView = 'APP.MANAGEMENT.BROKER.VIEW.CREDITPOLICY',
	ManagementBrokerDocumentsView = 'APP.MANAGEMENT.BROKER.VIEW.DOCUMENTS',
	ManagementCreditPolicyView = 'APP.MANAGEMENT.CREDITPOLICY.VIEW',
	CaseCreateView = 'APP.PROPOSALS.CREATE',
	CaseView = 'APP.PROPOSALS.VIEW',
	PropertiesView = 'APP.PROPERTIES.VIEW',
	AccountView = 'APP.ACCOUNT.VIEW',
	AccountNotifications = 'APP.ACCOUNT.NOTIFICATIONS',
	NotificationsView = 'APP.NOTIFICATIONS.VIEW',
	Search = 'APP.SEARCH',

	RealEstateFinancingRoot = 'APP.CASE.REALSTATE.ROOT',
	RealEstateFinancingCases = 'APP.CASE.REALSTATE.CASES',
	RealEstateFinancingCreate = 'APP.CASE.REALSTATE.FINANCING.CREATE',
	Financial = 'APP.FINANCIAL',
	Legal = 'APP.LEGAL',

	BrokerFinancial = 'APP.BROKER.FINANCIAL',
	BrokerFinancialBills = 'APP.BROKER.FINANCIAL.BILLS',
	ManagementFinancialView = 'APP.MANAGEMENT.FINANCIAL',
	ManagementFinancialBrokerBill = 'APP.MANAGEMENT.FINANCIAL.BrokerBill',
	ManagementFinancialPaymentRequests = 'APP.MANAGEMENT.FINANCIAL.PaymentRequests',
	ManagementFinancialBrokerBills = 'APP.MANAGEMENT.FINANCIAL.BrokerBills',
	ManagementDeals = 'APP.MANAGEMENT.FINANCIAL.DEALS',
}

export default Routes;
