import axios from "axios";

var http = axios.create({
  baseURL: "https://636016a0ca0fe3c21aac4e39.mockapi.io",
});

export class MockService {
  static async getProposals() {
    const response = await http.get("proposals");
    return response.data;
  }
}
