import { AppErrorMessage } from '@/components/App';
import { Messages } from '@/constants';
import axios, { type CancelTokenSource } from 'axios';
import { auth } from './firebaseService';

/**
 * Recurso utilizado para fazer as chamadas de API da Atta.
 */
export class Http {
	/**
	 * Cria uma nova instancia do Axios
	 * @param baseUrl Url base do endpoint
	 */
	static create(baseUrl: string | undefined = import.meta.env.VITE_API_SERVICE) {
		const _http = axios.create({
			baseURL: baseUrl,
		});

		_http.defaults.headers.post['Content-Type'] = 'application/json';

		// O Interceptador irá inserir sempre o token de autorização durante a requisição

		let cachedToken: any = null;
		let tokenExpiration: any = null;

		_http.interceptors.request.use(
			async (config) => {
				if (!cachedToken || Date.now() >= tokenExpiration) {
					const token = await auth.currentUser?.getIdToken(true);
					cachedToken = token;
					tokenExpiration = Date.now() + 60 * 60 * 1000; // token válido por 1 hora
				}
				//@ts-ignore
				config.headers['Authorization'] = `Bearer ${cachedToken}`;
				return config;
			},
			(error) => Promise.reject(error)
		);

		_http.interceptors.response.use(
			(response) => response,
			(error) => {
				const status = error.response ? error.response.status : null;

				if (status === 401) {
					//router.push({ name: ROUTERS.LOGIN_SILENT_REFRESH })
					//@ts-ignore
					window.$message.error(Messages.ERROR_API_INVALID_CREDENTIALS, {
						duration: 0,
					});
				}

				if (axios.isAxiosError(error)) {
					const code = error.code;

					if (code == 'ERR_NETWORK')
						//@ts-ignore
						window.$message.error(Messages.ERROR_API_CONNECTION, {
							duration: 0,
							closable: true,
						});

					if (code == 'ERR_BAD_RESPONSE') {
						let { ErrorMessage } = error.response?.data;

						//@ts-ignore
						window.$message.error(ErrorMessage ?? Messages.ERROR_API_CONNECTION, {
							duration: 0,
							//render: AppErrorMessage,
							closable: true,
						});
					}
				}

				return Promise.reject(error);
			}
		);

		return _http;
	}
}

export class HttpService {
	public static cancelTokens: { [key: string]: CancelTokenSource | null } = {};
	public static createCancelToken(key: string) {
		if (this.cancelTokens[key]) {
			this.cancelTokens[key]!.cancel(`Operation canceled due to new request for ${key}.`);
		}
		this.cancelTokens[key] = axios.CancelToken.source();
		return this.cancelTokens[key]!.token;
	}
}

export interface ApiResponse<T> {
	result: T;
	error: string;
	detail: Array<string>;
}

export default Http;
