import type { IMessage, IResponseBase } from "@/interfaces";
import { Http } from "./http";

const http = Http.create();

export class MessageService {
    
    /**
     * Send message based on entity id and type
     * @param text 
     * @param entityId 
     * @param entityType 
     * @returns 
     */
    public static async sendMessage(text: string, entityId: string, entityType: string){
        const response = await http.post<IResponseBase<boolean>>(`messages/${entityType}/${entityId}`, {text});
        return response.data;
    }
    
    /**
     * Get messages based on entity id and type
     * @param entityId 
     * @param entityType 
     * @returns 
     */
    public static async getMessages(entityId: string, entityType: string) {
        const response = await http.get<IResponseBase<IMessage[]>>(`messages/${entityType}/${entityId}`);
        return response.data;
    }
}