export const MAX_FINANCIAL_TERM_RESIDENTIAL = 420;
export const MAX_FINANCIAL_TERM_COMMERCIAL = 240;
export const MIN_FINANCIAL_TERM = 36;
export const MIN_FINANCIAL_VALUE = 100000;

export * from './GridifyFilter';
export * from './messages';
export * from './PaymentMethod';
export * from './PaymentType';
export * from './PersonType';
export * from './PropertyType';
export * from './UserRoles';
export * from './ProposalStatus';
export * from './DocumentType';
export * from './CaseType';
export * from './SubscribeType';
