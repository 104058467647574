import type { IFileResponse, IResponseBase } from '@/interfaces';
import { Http } from './http';
import type { DocumentSignRequestPayload } from '@/models/D4Sign';

const http = Http.create();

export class FileService {
	static async getDocumentById(id: string) {
		const response = await http.get<IResponseBase<IFileResponse>>(`file/${id}`);
		return response.data;
	}

	static async getDocumentSignRequest(id: string) {
		const response = await http.get<IResponseBase<DocumentSignRequestPayload>>(`file/${id}/sign-info`);
		return response.data;
	}

	/**
	 * Return a file path if success
	 * @param file
	 * @returns
	 */
	static async uploadFile(file: File, ownerId: string, ownerType: string) {
		const formData = new FormData();
		formData.append('file', file);

		const response = await http.post<string>(`file/${ownerId}/${ownerType}/upload`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return response.data;
	}

	/**
	 * Return a list of files from a owner
	 * @param ownerId
	 * @param ownerType
	 * @returns
	 */
	static async getOwnerFiles(ownerId: string, ownerType: string) {
		const response = await http.get<IResponseBase<IFileResponse[]>>(`file/${ownerType}/${ownerId}`);

		return response.data;
	}

	/**
	 * Download a file by id
	 * @param fileId
	 * @returns
	 */
	static getFile(fileId: string, method: 'download' | 'preview' | '' = '') {
		// const response = await http.get<string>(`file/${fileId}/download`, {
		//   responseType: 'blob',
		// });

		return `${import.meta.env.VITE_API_SERVICE}/file/storage/${fileId}/${method}`;
	}

	static deleteFile(fileId: string) {
		return http.delete(`file/${fileId}`);
	}
}
