import type { IBank, IResponseBase } from '@/interfaces';
import type { SelectBaseOption } from 'naive-ui/es/select/src/interface';
import { Http } from './http';

const http = Http.create();

export class GeneralService {
  static async getBanks() {
    const response = await http.get<IResponseBase<IBank[]>>('/services/banks');
    return response.data;
  }

  static async getEnumItems(enumName: string) {
    const response = await http.get<IResponseBase<SelectBaseOption[]>>(`/services/enum/${enumName}`);
    return response.data;
  }

  static async updateRenterScore(renterId: string) {
    const response = await http.patch(`/renter/${renterId}/spc-update`);
    return response.data;
  }

  static async updateRenterTJ(renterId: string) {
    const response = await http.patch(`/renter/${renterId}/tj-update`);
    return response.data;
  }

  static async sendDocumentSignEmail(renterId: string) {
    const response = await http.post(`/renter/${renterId}/document/resend`);
    return response.data;
  }
}

export default GeneralService;
