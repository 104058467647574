import { Colors } from '@/utils';
import { NAvatar, NTooltip } from 'naive-ui';
import { defineComponent, computed } from 'vue';

export const AppUserAvatar = defineComponent({
	props: {
		content: {
			type: String,
			default: 'A',
		},
		showTooltip: {
			type: Boolean,
			default: true,
		},
		size: {
			type: Number,
			default: 24,
		},
		circle: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { attrs }) {
		const initial = computed(() => {
			let words = props.content.split(' ');
			let initials = '';
			if (words.length >= 1) {
				initials += words[0].charAt(0).toUpperCase();
			}
			if (words.length >= 2) {
				initials += words[1].charAt(0).toUpperCase();
			}
			return initials;
		});

		const colors = Colors.generateColor(props.content);
		return () => (
			<NTooltip disabled={!props.showTooltip}>
				{{
					default: () => <span>{props.content}</span>,
					trigger: () => (
						<NAvatar {...{ attrs, circle: props.circle }} size={props.size} style={colors} class="m0">
							{initial.value}
						</NAvatar>
					),
				}}
			</NTooltip>
		);
	},
});

export default AppUserAvatar;
