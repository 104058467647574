import type { IResponseBase } from '@/interfaces';
import type { IWorkflow } from '@/interfaces/IWorkflow';
import { Http } from './http';
import type { IWorkflowActionEntry } from '@/interfaces/IWorkflowActionEntry';

const http = Http.create();

export class WorkflowService {
  /**
   * Set action value
   * @param activityId
   * @param action
   */
  static async doAction(entityId: string, activityId: string, action: string) {
    const response = await http.post<IResponseBase<IWorkflow>>(`workflow/${entityId}/activities/${activityId}`, {
      action,
    });

    return response.data;
  }

  /**
   * Get workflow activities by workflow id
   * @param workflowId
   * @returns
   */
  static async getWorkflowActivities(entityId: string, workflowId: string) {
    const response = await http.get<IResponseBase<IWorkflowActionEntry[]>>(`workflow/${entityId}/activities?workflowId=${workflowId}`);
    return response.data;
  }

  /**
   * Start workflow for a specific case
   * @param caseEntityId
   * @param caseType
   * @returns
   */
  static async startWorkflow(caseEntityId: string, caseType: string) {
    const response = await http.post<IResponseBase<IWorkflow>>(`workflow`, {
      caseEntityId,
      caseType,
    });

    return response.data;
  }

  static async forceActivityAction(activity: string, payload: Object) {
    if (activity == 'DocumentSignRequestActivity') {
      const response = await http.post<IResponseBase<IWorkflow>>(`webhook/sign-request/d4sign`, payload);
      return response.data;
    }
  }
}
