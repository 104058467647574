export class UserRoles {
    public static readonly Admin = 'ADMIN';
    public static readonly User = 'USER';
    public static readonly BrokerAdmin = 'BROKER_ADMIN';
    public static readonly BrokerUser = 'BROKER_USER';
    public static readonly BrokerAgent = 'BROKER_AGENT';
    public static readonly BUSINESS_CONSULTANT = 'BUSINESS_CONSULTANT';
    public static readonly BUSINESS_MANAGER = 'BUSINESS_MANAGER';
    public static readonly RENTER = 'RENTER';
    public static readonly options = [
        { label: 'Almada - Administrador', value: UserRoles.Admin },
        { label: 'Almada - Usuário', value: UserRoles.User },
        { label: 'Almada - Representante Comercial', value: UserRoles.BUSINESS_CONSULTANT },
        { label: 'Almada - Diretor Comercial', value: UserRoles.BUSINESS_MANAGER },
        { label: 'Almada - Usuário', value: UserRoles.User },
        { label: 'Parceiro - Administrador', value: UserRoles.BrokerAdmin },
        { label: 'Parceiro - Usuário', value: UserRoles.BrokerUser },
        { label: 'Parceiro - Corretor', value: UserRoles.BrokerAgent },
    ];

    public static buildOptions(role: string) {
        // Filter only Partner rules
        if (role == UserRoles.BrokerAdmin) {
            return UserRoles.options.filter((c) => ![UserRoles.Admin, UserRoles.User].includes(c.value));
        }

        return UserRoles.options;
    }

    public static getDescription(role: string) {
        return UserRoles.options.find((c) => c.value == role)?.label || 'Não mapeado';
    }
}
